.header
{
    margin-bottom: 30px !important;
    background-color: #fff !important;
    padding: 0 22px 0 0 !important;
    border-radius: 6px !important;
    /* box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07) !important; */

    box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow:0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    
}
.header .header-link
{
    font-weight: 500;
    color: darkslategray !important;
}
.header .header-link:hover
{
    color:#00CED1!important; 
}
.header .header-active
{
    font-weight: 500;
    color:#00CED1!important; 
}

.header .header-home
{
    background-color: #00CED1;
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.header .header-right
{
    display: flex !important;
    align-items: center !important;
    padding: 10px;

}

.header .header-right > a > .MuiSvgIcon-root
{
    font-size: 17px !important;
    margin-right: 12px;
    color: #000000;
}
.header .header-left
{
    margin-right: auto;
    text-transform: uppercase;
    font-weight:bold;
    padding: 10px;
}

