

.MuiTimelineItem-missingOppositeContent:before{
    display: none;
}

/* .MuiTimelineConnector-root{
    background-color: #eee !important;
} */
.timeline-dot
{
    color: #000000 !important;
    background-color: #00CED1 !important;
    padding: 2px !important;
}
.separator-padding
{
    padding-left: 19px !important;
}

.timeline-style .MuiTimelineItem-root{
    min-height: 0px;
    width: 110%;
}