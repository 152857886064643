
.alertIcon
{
    width: 23.5rem;
}

.alert-section
{
    padding: 100px 50px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}