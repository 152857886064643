.line
{
    border-left: solid 0.5rem #00CED1;
    margin-right: 0.7rem;
}

.section .title-text
{
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin: 0;
}


.skill
{
    border-radius: 8px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-height: 185px;
    height: 100%;
}

.skill .skill-title
{
    font-size: 19px !important;
    font-weight: 500 !important;
    line-height: 35px !important;
}

.skill .skill-description
{
    display: flex;
    align-items: center;
    font-weight: 600 !important;
    line-height: 18px !important;
    font-size: 14px;
    color: gray;
}

.skill .skill-description .timeline-dot
{
    padding: 2px !important;
    margin-right: 5px !important;
}

.skills-section
{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}
.skills-section > img
{
    width: 3rem;
    height: 3rem;
}