.about-me-text 
{
    color: #808080;
    font-size: 15px;
    white-space: pre-line;
    line-height: 20px !important;

}

.line
{
    border-left: solid 0.5rem #00CED1;
    margin-right: 0.7rem;
}
.banner
{
    width: 23rem;
    margin-top: 2rem;
}

.card-title-styles
{
    text-align: center;
    
}
.card-header-styles
{
    text-align: center;
    color:#808080;
}
.card-description-styles
{
    text-align: center;
    font-size: 14.5px;
    color:#808080;
}