.timeline-content
{
    margin-top: 2px !important;
    margin-bottom: 12px !important;
}
.timeline-text
{
   font-size: 13.5px !important;
   font-weight: 400 !important;
   color: #787878 !important; 
   text-decoration: none;
}

.timeline-text > span
{
  color: #000000;
}

/* link's color  */
.timeline-text > a
{
    color: #787878 !important; 
    text-decoration: none;
}

.download-button-container
{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0px 0px 20px 0px;
}

.download-button-container a:hover { text-decoration: none }