.footer
{
    background-color: #00CED1;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    border-radius: 8px;
    padding: 20px 25px;
}
.footer .footer-right
{
    font-size: small;
    text-align: end;
    color:#fff;
}
