.line
{
    border-left: solid 0.5rem #00CED1;
    margin-right: 0.7rem;
}

.comming-soon{
    color: #00CED1;
}

.project-section{
    
    flex-direction: row;
    display: flex;
    justify-content: space-around;
  
}

.prj-card{
    width: 15rem;
    height: 100%;
   
}

.description-p
{
    color: grey;
    font-size: 15px;
    white-space: pre-line;
}

