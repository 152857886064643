.availability,.avTitle{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    color: #808080;
}

.case-img{
    width: 17%;
    margin: auto;
    display: flex;
    justify-content: center;
 
 
   
}