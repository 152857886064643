
.line
{
    border-left: solid 0.5rem #00CED1;
    margin-right: 0.7rem;
}

.section .title-text
{
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin: 0;
}

/* Timeline section */
.timeline-description
{
    color: gray;
    margin-top: 6px;
    font-size: 18px !important;
    font-weight: 600 !important;
   
}



.timeline-date
{
    color: gray;
}
.timeline-title,.timeline-path,.timeline-major
{
    color: gray;
}
.timeline-title
{
    font-size: 16px !important;
    font-weight: 500 !important;
}

/* End of Timeline section */

