:root{
    --main-color: #00CED1;
}

.top-spacing
{
    margin-top:  60px !important;
}

@media (max-width: 991px)
{
    .top-spacing
    {
        margin-top:  30px !important;
    }
}

/* !______________________________ */
.p-spacing
{
    padding: 30px !important;
}

.pb-spacing
{
    padding-bottom: 45px !important;
}

.p-top
{
    margin-top: 30px !important;
}
.mb-spacing
{
    margin-bottom: 30px !important;
}
.p-50
{
    padding: 50px !important;
}
.pt-45
{
    padding-top: 45px !important;
}
/* !______________________________ */

.main-content
{
  background-color: #fff;
  border-radius:6px;
  /* padding: 30px; */
  margin-bottom: 1.5rem;
  
}
.section
{
    padding-left: 30px;
    padding-right: 30px;
}

.shadow
{
    box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow:0px 2px 92px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}

.section-title
{
    position: relative;
    display: inline-block;
    padding: 0 3px;
}
.section-title > .title-text
{
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin: 0;
}