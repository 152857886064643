*
{
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* //? CHANGE IT TO CSS GRADIENT(THE BACKGROUND) */
  background: #fafafa;
  overflow-x: hidden;
  /* overflow: hidden;
  background: url(./assets/img/bg2.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 60%; */

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


